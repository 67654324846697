@import '../../../CommonAssets/Styles/globalStyle';

.actionStickyContainerStyle {
    flex-direction: column;
}

.replayLink {
    display: flex;
    align-self: center;
    justify-content: center;
    padding-bottom: 3px;
    color: get-color(darkgray);
    font-weight: 700;
}
